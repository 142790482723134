@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

#Navbar {
  background: #002a58 !important;
  opacity: 1 !important;
  box-shadow: none !important;
  backdrop-filter: saturate(0) !important;
  background-opacity: 0 !important;
}

#Steps {
  position: relative;
}
#Steps::after {
  position: absolute;
  width: 100%;
  height: 100%;
  right: -1000px;
  top: 0;
  content: '';
  background: linear-gradient(
    269.68deg,
    hsla(0, 0%, 100%, 0.1) 2.52%,
    hsla(0, 0%, 100%, 0.03) 72.25%,
    hsla(0, 0%, 100%, 0) 99.37%
  );
  transform: rotate(-30.04deg);
}
#Steps::before {
  position: absolute;
  width: 100%;
  height: 100%;
  left: -1000px;
  top: 0;
  content: '';
  background: linear-gradient(
    269.68deg,
    hsla(0, 0%, 100%, 0.1) 2.52%,
    hsla(0, 0%, 100%, 0.03) 72.25%,
    hsla(0, 0%, 100%, 0) 99.37%
  );
  transform: matrix(-0.87, -0.5, -0.5, 0.87, 0, 0);
}
